import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

//img
import login1 from "../../../assets/images/login/1.jpeg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";
import logo from "../../../assets/images/logo-wname.png";

const apiUrl = process.env.REACT_APP_API_URL;

SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loggedUsername = localStorage.getItem("username");
    const userId = localStorage.getItem("userid");

    if (loggedUsername && userId) {
      if (loggedUsername == "admin") {
        navigate("/admin");
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  const formValidation = () => {
    let isValid = true;

    if (!username) {
      toast.warn("Username is required");
      isValid = false;
    }

    if (!password) {
      toast.warn("Password is required");
      isValid = false;
    }

    return isValid;
  };

  async function fetchUserBalance(userId) {
    try {
      const response = await fetch(`${apiUrl}/getBalance.php?userid=${userId}`);
      if (response.status === 200) {
        const result = await response.json();
        localStorage.setItem("balance", result.balance);
      } else {
        toast.error("Failed to retrieve balance");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while fetching the balance");
    }
  }

  async function submitLogin() {
    try {
      if (formValidation()) {
        let data = new URLSearchParams({
          username: username,
          password: password,
        });
        const response = await fetch(`${apiUrl}/auth.php?${data.toString()}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (response.status === 200) {
          let result = await response.text();

          console.log("API Response:", result);

          let jsondata = JSON.parse(result);

          if (jsondata.userid) {
            let loggedUsername = jsondata.username;
            let userId = jsondata.userid;

            localStorage.setItem("username", loggedUsername);
            localStorage.setItem("userid", userId);

            await fetchUserBalance(userId);

            if (loggedUsername === "admin") {
              navigate("/admin");
            } else {
              navigate("/");
            }
          } else {
            toast.error("Invalid credentials, please try again.");
          }
        } else {
          toast.error("An error occurred while logging in. Please try again.");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("An unexpected error occurred. Please try again.");
    }
  }

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Extraordinarily High Calls
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        per second ratio while maintaining excellent <br />
                        completion rates.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />

                      <p className="font-size-16 text-white mb-0">
                        Our services are ideal for wholesale operators, calling{" "}
                        <br />
                        card and call shop operations, fixed and mobile
                        operators.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        DIDs in 50 US states
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        US Toll Free Numbers with competitive rates for inbound
                        calls. <br /> Also, we can provide Vanity US Local and
                        Toll Free numbers. <br />
                        We can provision from 12,000 rate centers and cities in
                        the US.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/"
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img src={logo} className="w-50"></img>
                </Link>
                <div className="mt-5">
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Username</h6>
                    <Form.Control
                      type="username"
                      className="form-control mb-0"
                      value={username}
                      placeholder="Enter username"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label fw-bold">Your Password</h6>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        className="form-control mb-0"
                        value={password}
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                        className="border border-light"
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    type="submit"
                    onClick={() => submitLogin()}
                  >
                    Sign in
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </section>
    </>
  );
};

export default SignIn;
