import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Select from "react-select";
import "rsuite/dist/rsuite-no-reset.min.css";
import * as XLSX from "xlsx"; // Import xlsx library

// Rsuitejs
import { DateRangePicker, Stack, Pagination } from "rsuite";

const apiUrl = process.env.REACT_APP_API_URL;

const Billing = () => {
  const Choice = [
    {
      value: "Inbound Call",
      label: "Inbound",
    },
    {
      value: "Outbound Call",
      label: "Outbound",
    },
    {
      value: "Both",
      label: "Both Inbound & Outbound",
    },
  ];

  const today = new Date();

  // State management
  const [dateRange, setDateRange] = useState([today, today]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [calls, setCalls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchClicked, setSearchClicked] = useState(false); // New state to track search button click
  const { afterToday } = DateRangePicker;

  const handleReset = () => {
    setDateRange([today, today]);
    setSelectedOption(null);
    setCurrentPage(1);
    setCalls([]);
    setSearchClicked(false); // Reset search click state
  };

  const handleSearch = async () => {
    setSearchClicked(true); // Mark search as clicked

    try {
      const [startDate, endDate] = dateRange;
      const formattedStartDate = startDate
        ? `${startDate
            .toISOString()
            .slice(0, 10)} ${startDate.toLocaleTimeString("en-GB", {
            hour12: false,
          })}`
        : null;
      const formattedEndDate = endDate
        ? `${endDate.toISOString().slice(0, 10)} ${endDate.toLocaleTimeString(
            "en-GB",
            { hour12: false }
          )}`
        : null;

      console.log(formattedStartDate);
      console.log(formattedEndDate);
      console.log(selectedOption);

      let data = new URLSearchParams({
        userid: localStorage.getItem("userid"),
        datefrom: formattedStartDate,
        dateto: formattedEndDate,
      });
      
      const response = await fetch(
        `${apiUrl}/getBilling.php?${data.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        data = parseResponse(await response.text());
        setCalls(filterCalls(data));
        setCurrentPage(1); // Reset to the first page after search
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const parseResponse = (response) => {
    const newResponse = response.replace(/I WAS HERE/, "");
    const jsonStringArray = newResponse
      .split("<pre>")
      .filter((str) => str.trim() !== "");
    return jsonStringArray.map((jsonString) => JSON.parse(jsonString));
  };

  const filterCalls = (calls) => {
    if (
      selectedOption === null ||
      selectedOption.label === "Both Inbound & Outbound"
    ) {
      return calls;
    } else {
      return calls.filter((call) => call.call_type === selectedOption.value);
    }
  };

  // Calculate totals
  const totalMinutes = calls.reduce(
    (sum, call) => sum + parseFloat(call.billed || 0),
    0
  );
  const totalAmount = calls.reduce(
    (sum, call) => sum + parseFloat(call.amount || 0),
    0
  );

  // Pagination logic
  const totalRecords = calls.length;
  const totalPages = Math.ceil(totalRecords / pageSize);

  const paginateCalls = (calls) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return calls.slice(startIndex, endIndex);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(calls);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Billing Data");

    // Create a file and trigger download
    XLSX.writeFile(workbook, "BillingReport.xlsx");
  };

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12" lg="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Billing Report</h3>
                <p className="text-white">Report</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body className="p-4 align-items-center mb-3">
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={downloadExcel}
                    disabled={calls.length === 0} // Disable when no data
                  >
                    Download Excel
                  </Button>
                </div>
                <div className="container mb-5">
                  <div className="row">
                    <div className="col-sm">
                      <h5 className="mb-2 mt-3">Date Range</h5>
                      <Stack
                        spacing={10}
                        direction="column"
                        alignItems="flex-start"
                      >
                        <DateRangePicker
                          format="MM-dd-yyyy HH:mm:ss"
                          character=" ~ "
                          shouldDisableDate={afterToday()}
                          size="lg"
                          value={dateRange}
                          showMeridian={false}
                          showSeconds
                          onChange={setDateRange}
                          required
                        />
                      </Stack>
                    </div>
                    <div className="col-sm">
                      <h5 className="mb-2 mt-3">Filter of Calls</h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedOption}
                        onChange={setSelectedOption}
                        isClearable
                        options={Choice}
                      />
                    </div>
                  </div>
                </div>
                <Button variant="primary" onClick={handleSearch}>
                  Search
                </Button>{" "}
                <Button variant="danger" onClick={handleReset}>
                  Reset
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                {calls.length === 0 && searchClicked ? (
                  <div className="text-center text-muted">
                    No data available for the selected date range.
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-between mb-3">
                      <span>Total Minutes: {totalMinutes.toFixed(2)}</span>
                      <span>Total Amount: ${totalAmount.toFixed(4)}</span>
                    </div>
                    <div className="table-responsive ">
                      <table className="table text-center">
                        <thead>
                          <tr>
                            <th scope="col">Date Time </th>
                            <th scope="col">Country</th>
                            <th scope="col">Call type</th>
                            <th scope="col">CallerID</th>
                            <th scope="col">Called Number</th>
                            <th scope="col">Billed minutes</th>
                            <th scope="col">Rate</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody className="align-item-center ">
                          {paginateCalls(calls).map((call, index) => (
                            <tr key={index}>
                              <td>{call.calltime}</td>
                              <td>{call.country}</td>
                              <td>{call.call_type}</td>
                              <td>{call.callerid}</td>
                              <td>{call.called}</td>
                              <td>{call.billed}</td>
                              <td>{call.rate}</td>
                              <td>{call.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        Total Records: {totalRecords} | Page {currentPage} of{" "}
                        {totalPages}
                      </span>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        total={totalRecords}
                        limit={pageSize}
                        activePage={currentPage}
                        onChangePage={setCurrentPage}
                      />
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Billing;
