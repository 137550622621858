import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { DateRangePicker, Stack, Pagination } from "rsuite";
import * as XLSX from "xlsx";
import "rsuite/dist/rsuite-no-reset.min.css";

const apiUrl = process.env.REACT_APP_API_URL;

const CallHistory = () => {
  const { afterToday } = DateRangePicker;

  // State management
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [calls, setCalls] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchClicked, setSearchClicked] = useState(false);

  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleReset = () => {
    setDateRange([new Date(), new Date()]);
    setCurrentPage(1);
    setCalls([]);
    setTotalRecords(0);
    setSearchClicked(false);
  };

  const handleSearch = async () => {
    setSearchClicked(true);
    try {
      const [startDate, endDate] = dateRange;

      const formattedStartDate = startDate
        ? `${startDate
            .toISOString()
            .slice(0, 10)} ${startDate.toLocaleTimeString("en-GB", {
            hour12: false,
          })}`
        : null;
      const formattedEndDate = endDate
        ? `${endDate.toISOString().slice(0, 10)} ${endDate.toLocaleTimeString(
            "en-GB",
            { hour12: false }
          )}`
        : null;

      console.log(formattedStartDate);
      console.log(formattedEndDate);

      let data = new URLSearchParams({
        userid: localStorage.getItem("userid"),
        datefrom: formattedStartDate,
        dateto: formattedEndDate,
      });

      const response = await fetch(
        `${apiUrl}/getCallHistory.php?${data.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        data = parseResponse(await response.text());
        const filteredData = filterCalls(data);
        setCalls(filteredData);
        setTotalRecords(filteredData.length);
        setCurrentPage(1);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const parseResponse = (response) => {
    const jsonStringArray = response
      .split("<pre>")
      .filter((str) => str.trim() !== "");
    return jsonStringArray.map((jsonString) => JSON.parse(jsonString));
  };

  const filterCalls = (calls) => {
    if (
      selectedOption === null ||
      selectedOption.label === "Both Inbound & Outbound"
    ) {
      return calls;
    } else {
      return calls.filter((call) => call.call_type === selectedOption.value);
    }
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(calls);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CallHistory");

    // Create a file and trigger download
    XLSX.writeFile(workbook, "CallHistoryReport.xlsx");
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedItems = calls.slice(startIndex, startIndex + itemsPerPage);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12" lg="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Calling History</h3>
                <p className="text-white">History</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body className="p-4 align-items-center mb-3">
                <div className="d-flex justify-content-end mb-3">
                  <Button
                    variant="primary"
                    onClick={downloadExcel}
                    disabled={calls.length === 0} // Disable when no data
                  >
                    Download Excel
                  </Button>
                </div>
                <div className="container mb-5">
                  <div className="row">
                    <div className="col-sm">
                      <h5 className="mb-2 mt-3">Date Range</h5>
                      <Stack
                        spacing={10}
                        direction="column"
                        alignItems="flex-start"
                      >
                        <DateRangePicker
                          format="MM-dd-yyyy HH:mm:ss"
                          character=" ~ "
                          shouldDisableDate={afterToday()}
                          size="lg"
                          value={dateRange}
                          showMeridian={false}
                          showSeconds
                          onChange={setDateRange}
                          required
                        />
                      </Stack>
                    </div>
                  </div>
                </div>
                <Button variant="primary" onClick={handleSearch}>
                  Search
                </Button>
                {"   "}
                <Button variant="danger" onClick={handleReset}>
                  Reset
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                {calls.length === 0 && searchClicked ? (
                  <div className="text-center text-muted">
                    No data available for the selected date range.
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table text-center">
                        <thead>
                          <tr>
                            <th scope="col">Trunk</th>
                            <th scope="col">Date Time </th>
                            <th scope="col">Country</th>
                            <th scope="col">Call type</th>
                            <th scope="col">CallerID</th>
                            <th scope="col">Called</th>
                            <th scope="col">Call Code</th>
                            <th scope="col">Duration mins</th>
                            <th scope="col">Call Reference</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedItems.map((item, idx) => (
                            <tr key={idx}>
                              <td>{item.trunk}</td>
                              <td>{item.calltime}</td>
                              <td>{item.country}</td>
                              <td>{item.call_type}</td>
                              <td>{item.callerid}</td>
                              <td>{item.called}</td>
                              <td>{item.call_code}</td>
                              <td>{item.duartion}</td>
                              <td>{item.call_reference}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <span className="ml-3">
                        Total Records: {totalRecords} | Page {currentPage} of{" "}
                        {totalPages}
                      </span>

                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        total={totalPages}
                        limit={1}
                        activePage={currentPage}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CallHistory;
