import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "rsuite";

const apiUrl = process.env.REACT_APP_API_URL;

const UpdateClient = () => {
  const [validated, setValidated] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [refillData, setRefillData] = useState([]);
  const [showRefillLog, setShowRefillLog] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    username: "",
    email: "",
    trunk: "",
    ipaddress: "",
    userid: "", // Non-editable
    balance: "", // Non-editable
    password: "",
    password2: "",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page

  // Effect to fetch client list
  useEffect(() => {
    listClients();
  }, []);

  // Effect to check password match
  useEffect(() => {
    if (clientInfo.password !== clientInfo.password2) {
      setPasswordError("Passwords do not match");
      setIsPasswordMatch(false);
    } else {
      setPasswordError("");
      setIsPasswordMatch(true);
    }
  }, [clientInfo.password, clientInfo.password2]);

  // Function to fetch clients
  async function listClients() {
    try {
      const response = await fetch(`${apiUrl}/listClients.php`, {
        method: "POST",
      });
      let result = await response.text();

      const jsonDataArray = result
        .split("<br>")
        .map((item) => {
          try {
            return JSON.parse(item);
          } catch (e) {
            console.error("Error parsing JSON:", e);
            return null;
          }
        })
        .filter((item) => item !== null);

      const companyDataList = jsonDataArray
        .map((item) => ({
          company_name: item.company_name,
          userid: item.userid,
        }))
        .filter((item) => item.company_name);

      setCompanyData(companyDataList);

      console.log(companyDataList);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }

  async function fetchClientInfo(userid) {
    try {
      const response = await fetch(
        `${apiUrl}/getClientInfo.php?userid=${userid}`
      );

      if (response.ok) {
        const data = await response.json();
        setClientInfo({
          userid: userid,
          username: data.username,
          email: data.email,
          trunk: data.trunk,
          ipaddress: data.ipaddress,
          balance: data.balance,
          password: data.password,
          password2: data.password,
        });
      } else {
        console.error("Error fetching client information.");
      }
    } catch (error) {
      console.error("Error fetching client information:", error);
    }
  }

  async function handleView() {
    setViewClicked(true);
    setShowRefillLog(true);

    try {
      let data = new URLSearchParams({
        userid: clientInfo.userid,
      });

      const response = await fetch(
        `${apiUrl}/getRefill.php?${data.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.ok) {
        const responseText = await response.text();
        const refillDataParsed = parseResponse(responseText);
        setRefillData(refillDataParsed);
      } else {
        console.error("Error fetching refill log data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Parse API response
  const parseResponse = (response) => {
    const jsonStringArray = response
      .split("<br>")
      .filter((str) => str.trim() !== ""); // Remove empty strings or unwanted HTML tags
    return jsonStringArray
      .map((jsonString) => {
        try {
          return JSON.parse(jsonString); // Parse the JSON string
        } catch (e) {
          console.error("Error parsing JSON:", e);
          return null; // Handle error cases
        }
      })
      .filter((item) => item !== null); // Filter out null values
  };

  const handleCompanyChange = (e) => {
    const selectedUserId = e.target.value;

    if (selectedUserId) {
      setClientInfo((prevInfo) => ({
        ...prevInfo,
        userid: selectedUserId,
      }));

      fetchClientInfo(selectedUserId);
    } else {
      setClientInfo({
        username: "",
        email: "",
        trunk: "",
        ipaddress: "",
        userid: "",
        balance: "",
        password: "",
        password2: "",
      });
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Handle form submission for updating client info
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPasswordMatch) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/updateClientInfo.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          userid: clientInfo.userid,
          username: clientInfo.username,
          password: clientInfo.password,
          email: clientInfo.email,
          trunk: clientInfo.trunk,
          ipaddress: clientInfo.ipaddress,
        }),
      });

      const result = await response.text();

      if (response.ok) {
        toast.success("Client information updated successfully.");
      } else {
        toast.error("Failed to update client information.");
      }

      console.log("API Response:", result);
    } catch (error) {
      console.error("Error updating client information:", error);
      toast.error("An error occurred while updating the client information.");
    }
  };

  // Handle refill log view

  // Calculate current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = refillData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12" lg="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Client</h3>
                <p className="text-white">Update Client Information</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="row g-3"
                >
                  <div className="col-12">
                    <Form.Label className="form-label" htmlFor="company_name">
                      Company Name
                    </Form.Label>
                    <Form.Select onChange={handleCompanyChange} required>
                      <option value="">Select Company</option>
                      {companyData.map((company) => (
                        <option key={company.userid} value={company.userid}>
                          {company.company_name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please select a company name.
                    </Form.Control.Feedback>
                  </div>

                  {/* Non-editable Fields */}
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="userid">
                      UserID
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="userid"
                      value={clientInfo.userid}
                      readOnly
                    />
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="balance">
                      Balance
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="balance"
                      value={clientInfo.balance}
                      readOnly
                    />
                  </Col>

                  {/* Editable Fields */}
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="username">
                      Username
                    </Form.Label>
                    <div className="input-group has-validation">
                      <span className="input-group-text" id="inputGroupPrepend">
                        @
                      </span>
                      <Form.Control
                        type="text"
                        id="username"
                        value={clientInfo.username}
                        onChange={(e) =>
                          setClientInfo({
                            ...clientInfo,
                            username: e.target.value,
                          })
                        }
                        required
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please enter your username.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="email">
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      value={clientInfo.email}
                      onChange={(e) =>
                        setClientInfo({ ...clientInfo, email: e.target.value })
                      }
                      required
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter your email.
                    </Form.Control.Feedback>
                  </Col>

                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="ipaddress">
                      IP Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="ipaddress"
                      value={clientInfo.ipaddress}
                      onChange={(e) =>
                        setClientInfo({
                          ...clientInfo,
                          ipaddress: e.target.value,
                        })
                      }
                      required
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid IP Address.
                    </Form.Control.Feedback>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="trunk">
                      Trunk
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="trunk"
                      value={clientInfo.trunk}
                      onChange={(e) =>
                        setClientInfo({ ...clientInfo, trunk: e.target.value })
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter trunk.
                    </Form.Control.Feedback>
                  </Col>

                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="password">
                      Password
                    </Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={clientInfo.password}
                        onChange={(e) =>
                          setClientInfo({
                            ...clientInfo,
                            password: e.target.value,
                          })
                        }
                        required
                      />
                      <Button
                        variant="outline-secondary"
                        className="rounded border border-light"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        Please provide a password.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="password2">
                      Confirm Password
                    </Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        id="password2"
                        value={clientInfo.password2}
                        onChange={(e) =>
                          setClientInfo({
                            ...clientInfo,
                            password2: e.target.value,
                          })
                        }
                        required
                        isInvalid={!isPasswordMatch}
                        className={!isPasswordMatch ? "is-invalid" : ""}
                      />
                      <Button
                        variant="outline-secondary"
                        className="rounded border border-light"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? "Hide" : "Show"}
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        {passwordError || "Enter your password again."}
                      </Form.Control.Feedback>
                    </div>
                  </Col>

                  <div className="col-12">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={!clientInfo.userid}
                    >
                      Update
                    </Button>
                    {"  "}
                    <Button
                      variant="primary"
                      className="btn btn-info"
                      onClick={handleView}
                      disabled={!clientInfo.userid}
                    >
                      View Refill Log
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          {showRefillLog && (
            <Col sm="12" lg="12">
              <Card>
                <Card.Body>
                  {refillData.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Date of Refill</th>
                              <th scope="col">Refilled By</th>
                              <th scope="col">Refill Amount</th>
                              <th scope="col">Total Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.date}</td>
                                <td>{item.createdby}</td>
                                <td>{item.refill}</td>
                                <td>{item.balance}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* Pagination Component */}
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "pager"]}
                        total={refillData.length}
                        limit={itemsPerPage}
                        activePage={currentPage}
                        onChangePage={handlePageChange}
                      />
                    </>
                  )}{" "}
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default UpdateClient;
