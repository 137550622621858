import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = process.env.REACT_APP_API_URL;

const SignUp = () => {
  const [validated, setValidated] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const password = form.password.value;
    const confirmPassword = form.password2.value;

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      setIsPasswordMatch(false);
      setValidated(true); // Display invalid feedback for password mismatch
    } else {
      setPasswordError("");
      setIsPasswordMatch(true);
    }

    if (form.checkValidity() === false || !isPasswordMatch) {
      event.stopPropagation();
      setValidated(true); // Display invalid feedback for form validation
    } else {
      setValidated(false); // Reset validation state

      const formData = new URLSearchParams({
        username: form.username.value,
        company_name: form.company_name.value,
        email: form.email.value,
        trunk: form.trunk.value,
        ipaddress: form.ipaddress.value,
        password: form.password.value,
        password2: form.password2.value,
      });

      try {
        const response = await fetch(`${apiUrl}/register.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        });

        if (response.ok) {
          toast.success("Registration successful!");
          form.reset();
          setValidated(false);
          setPasswordError("");
          setIsPasswordMatch(true);
        } else {
          const errorData = await response.json();
          console.error("Error Data: ", errorData);
          toast.error(
            `Registration failed: ${errorData.message || "Please try again."}`
          );
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred. Please try again later.");
      }
    }
  }

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12" lg="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Sign Up</h3>
                <p className="text-white">Register a Client</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body className="pt-0">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="row g-3"
                >
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="username">
                      Username
                    </Form.Label>
                    <div className="input-group has-validation">
                      <span className="input-group-text" id="inputGroupPrepend">
                        @
                      </span>
                      <Form.Control
                        type="text"
                        id="username"
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please enter your username.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="email">
                      Email
                    </Form.Label>
                    <Form.Control type="email" id="email" required />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter your email.
                    </Form.Control.Feedback>
                  </Col>
                  <div className="col-12">
                    <Form.Label className="form-label" htmlFor="company_name">
                      Company Name
                    </Form.Label>
                    <Form.Control type="text" id="company_name" required />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter your company name.
                    </Form.Control.Feedback>
                  </div>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="ipaddress">
                      IP Address
                    </Form.Label>
                    <Form.Control type="text" id="ipaddress" required />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid IP Address.
                    </Form.Control.Feedback>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="trunk">
                      Trunk
                    </Form.Label>
                    <Form.Control type="text" id="trunk" required />
                    <Form.Control.Feedback type="invalid">
                      Please enter trunk.
                    </Form.Control.Feedback>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="password">
                      Password
                    </Form.Label>
                    <Form.Control type="password" id="password" required />
                    <Form.Control.Feedback type="invalid">
                      Please provide a password.
                    </Form.Control.Feedback>
                  </Col>
                  <Col md="6" className="">
                    <Form.Label className="form-label" htmlFor="password2">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      id="password2"
                      required
                      isInvalid={!isPasswordMatch}
                      className={!isPasswordMatch ? "is-invalid" : ""}
                    />
                    <Form.Control.Feedback type="invalid">
                      {passwordError || "Enter your password again."}
                    </Form.Control.Feedback>
                  </Col>
                  <div className="col-12">
                    <Form.Check className="">
                      <Form.Check.Input
                        type="checkbox"
                        id="invalidCheck"
                        required
                      />
                      <Form.Check.Label htmlFor="invalidCheck">
                        Agree to terms and conditions
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="col-12">
                    <Button type="submit" variant="primary">
                      Submit form
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default SignUp;
