import React, { useState, useContext } from "react";

//router
import { Link, useLocation } from "react-router-dom";

//react-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = React.memo(() => {
  let username = localStorage.getItem("username");

  let dashboardPath = "/";

  if (username === "admin") {
    dashboardPath = "/admin";
  }

  //location
  let location = useLocation();

  let path = location.pathname;

  return (
    <React.Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
        <li
          className={`${location.pathname === "/" ? "active" : ""} nav-item `}
        >
          <Link
            className={`${
              path === "/" || path === "/admin" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to={dashboardPath}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Dashboard</Tooltip>}
            >
              <i className="icon material-symbols-outlined">newspaper</i>
            </OverlayTrigger>
            <span className="item-name">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Reports</span>
            <span
              className="mini-icon"
              data-bs-toggle="tooltip"
              title="Social"
              data-bs-placement="right"
            >
              -
            </span>
          </Link>
        </li>

        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/BillingReport" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/BillingReport"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Billing Report</Tooltip>}
            >
              <i className="icon material-symbols-outlined">assignment</i>
            </OverlayTrigger>
            <span className="item-name">Billing Report</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/CallHistory" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/CallHistory"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Call History</Tooltip>}
            >
              <i className="icon material-symbols-outlined">history</i>
            </OverlayTrigger>
            <span className="item-name">Call History</span>
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link
            className={`${
              location.pathname === "/CallSummary" ? "active" : ""
            } nav-link `}
            aria-current="page"
            to="/CallSummary"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Call Summary</Tooltip>}
            >
              <i className="icon material-symbols-outlined">summarize</i>
            </OverlayTrigger>
            <span className="item-name">Call Summary</span>
          </Link>
        </Nav.Item>

        {username === "admin" && (
          <Nav.Item as="li">
            <Link
              className={`${
                location.pathname === "/UpdateClient" ? "active" : ""
              } nav-link `}
              aria-current="page"
              to="/UpdateClient"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Client</Tooltip>}
              >
                <i className="icon material-symbols-outlined">person</i>
              </OverlayTrigger>
              <span className="item-name">Client</span>
            </Link>
          </Nav.Item>
        )}

        {username === "admin" && (
          <Nav.Item as="li">
            <Link
              className={`${
                location.pathname === "/Register" ? "active" : ""
              } nav-link `}
              aria-current="page"
              to="/Register"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Register</Tooltip>}
              >
                <i className="icon material-symbols-outlined">person</i>
              </OverlayTrigger>
              <span className="item-name">Register</span>
            </Link>
          </Nav.Item>
        )}
      </Accordion>
    </React.Fragment>
  );
});

export default VerticalNav;
