import React from "react";

// Layouts
import Default from "../layouts/dashboard/default";

import Client from "../views/dashboard/client";
import Index from "../views/dashboard";

// Routes
import { DefaultRouter } from "./default-router";

export const IndexRouters = [
  {
    path: "/",
    element: <Default />,
    children: [
      {
        path: "",
        element: <Client />,
      },
      ...DefaultRouter, // Add other routes here
    ],
  },
  {
    path: "/admin",
    element: <Default />,
    children: [
      {
        path: "",
        element: <Index />,
      },
      ...DefaultRouter, // Add other routes here
    ],
  },
];
