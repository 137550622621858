import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx"; // Import xlsx library

import login3 from "../../assets/images/login/3.jpg";

function Client() {
  const navigate = useNavigate();

  useEffect(() => {
    const userName = localStorage.getItem("username");
    if (!userName) {
      navigate("/auth/sign-in");
    } else if (userName === "admin") {
      navigate("/admin");
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Container
      fluid
      className="p-0"
      style={{
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={login3}
        className="img-fluid"
        alt="images"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
    </Container>
  );
}

export default Client;
