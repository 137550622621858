import React from "react";

import Billing from "../views/dashboard/billing";
import CallHistory from "../views/dashboard/callhistory";

import SignUp from "../views/dashboard/sign-up";
import UpdateClient from "../views/dashboard/updateClient";
import CallSummary from "../views/dashboard/callsummary";

export const DefaultRouter = [
  {
    path: "BillingReport",
    element: <Billing />,
  },
  {
    path: "CallHistory",
    element: <CallHistory />,
  },
  {
    path: "Register",
    element: <SignUp />,
  },
  {
    path: "UpdateClient",
    element: <UpdateClient />,
  },
  {
    path: "CallSummary",
    element: <CallSummary />,
  },
];
