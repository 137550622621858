import React from "react";

//header
import Header from "../../components/partials/dashboard/HeaderStyle/header";

//sidebar
import Sidebar from "../../components/partials/dashboard/SidebarStyle/sidebar";

//settingoffCanvas
import { Outlet } from "react-router-dom";

const Default = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="position-relative">
          <Header />

          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Default;
