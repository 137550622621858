import React, { useRef, useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

function Index() {
  const [userid, setUserid] = useState("");
  const [refillid, setRefillid] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [customerBalance, setCustomerBalance] = useState(0);
  const [refill, setRefill] = useState("");

  const [show0, setShow0] = useState(false);
  const handleClose0 = () => setShow0(false);
  const handleShow0 = () => setShow0(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const navigate = useNavigate();

  const [isEnableChecked, setIsEnableChecked] = useState(false);
  const [isDisableChecked, setIsDisableChecked] = useState(false);

  useEffect(() => {
    const loggedUsername = localStorage.getItem("username");

    if (loggedUsername !== "admin") {
      navigate("/");
    }
  });

  useEffect(() => {
    const userName = localStorage.getItem("username");
    if (!userName) {
      navigate("/auth/sign-in");
    }
    listClients();

    startSessionTimeout();

    window.addEventListener("mousemove", resetSessionTimeout);
    window.addEventListener("keydown", resetSessionTimeout);

    // Auto logout when tab or window is closed
    window.addEventListener("beforeunload", handleWindowClose);

    return () => {
      clearTimeout(sessionTimeoutRef.current);
      window.removeEventListener("mousemove", resetSessionTimeout);
      window.removeEventListener("keydown", resetSessionTimeout);
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, [navigate]);

  const sessionTimeoutRef = useRef(null);

  const startSessionTimeout = () => {
    // Logout after 10 minutes of inactivity
    sessionTimeoutRef.current = setTimeout(() => {
      handleLogout();
    }, 600000); // 10 minutes
  };

  const resetSessionTimeout = () => {
    // Reset the session timeout on user activity
    clearTimeout(sessionTimeoutRef.current);
    startSessionTimeout();
  };

  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
    localStorage.removeItem("balance");
    navigate("/auth/sign-in");
  };

  const handleWindowClose = () => {
    handleLogout();
  };

  async function listClients() {
    try {
      const response = await fetch(`${apiUrl}/listClients.php`, {
        method: "POST",
      });
      let result = await response.text();

      const jsonDataArray = result
        .split("<br>")
        .map((item) => {
          try {
            return JSON.parse(item);
          } catch (e) {
            console.error("Error parsing JSON:", e);
            return null;
          }
        })
        .filter((item) => item !== null);

      const companyDataList = jsonDataArray
        .map((item) => ({
          company_name: item.company_name,
          userid: item.userid,
          enabled: item.enabled, // Include the enabled status
        }))
        .filter((item) => item.company_name);

      setCompanyData(companyDataList);

      console.log(companyDataList);
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }

  async function getBalanceByUserId(userId) {
    try {
      let data = new URLSearchParams({
        userid: userId,
      });
      const response = await fetch(
        `${apiUrl}/getBalance.php?${data.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        let result = await response.text();
        let jsondata = JSON.parse(result);
        jsondata = jsondata.balance;
        setCustomerBalance(jsondata);
      }
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  }

  // Refill
  async function refillBalance(pickedUserId) {
    try {
      const storedUserId = pickedUserId;
      if (storedUserId) {
        let data = new URLSearchParams({
          userid: storedUserId,
          refill: refill,
        });

        const response = await fetch(
          `${apiUrl}/refillBalance.php?${data.toString()}`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          let result = await response.text();
          console.log(result);

          await getBalanceByUserId(storedUserId);

          const newBalance = parseFloat(customerBalance) + parseFloat(refill);
          if (localStorage.getItem("userid") === pickedUserId) {
            localStorage.setItem("balance", newBalance);
            const header = document.getElementById("accountBalance");
            header.textContent = "$ " + newBalance;
          }

          setCustomerBalance(newBalance);
          resetForm(); // Reset form after refill
        } else {
          console.error("Error during refill process:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error during refill process:", error);
    }
  }

  async function updateClientStatus(userid, enable) {
    try {
      let data = new URLSearchParams({
        userid: userid,
        enable: enable,
      });

      const response = await fetch(
        `${apiUrl}/enableClient.php?${data.toString()}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.status === 200) {
        let result = await response.text();
        console.log(result);

        listClients(); // Refresh company data after updating status
      } else {
        console.error("Error updating client status:", response);
      }
    } catch (error) {
      console.error("Error during status update:", error);
    }
  }

  // Update radio buttons based on the selected company's enabled status
  useEffect(() => {
    if (refillid) {
      const selectedCompany = companyData.find(
        (company) => company.userid === refillid
      );
      if (selectedCompany) {
        if (selectedCompany.enabled === "1") {
          setIsEnableChecked(true);
          setIsDisableChecked(false);
        } else if (selectedCompany.enabled === "0") {
          setIsEnableChecked(false);
          setIsDisableChecked(true);
        }
      }
    }
  }, [refillid, companyData]);

  const handleEnableClick = () => {
    if (!isEnableChecked) {
      handleShow1();
    }
  };

  const handleDisableClick = () => {
    if (!isDisableChecked) {
      handleShow0();
    }
  };

  const confirmEnable = async () => {
    await updateClientStatus(refillid, "1"); // Enable the client
    setIsEnableChecked(true);
    setIsDisableChecked(false);
    handleClose1();
  };

  const confirmDisable = async () => {
    await updateClientStatus(refillid, "0"); // Disable the client
    setIsDisableChecked(true);
    setIsEnableChecked(false);
    handleClose0();
  };

  const resetForm = () => {
    setRefillid(""); // Reset dropdown
    setRefill(""); // Reset refill amount
    setIsEnableChecked(false); // Uncheck Enable radio button
    setIsDisableChecked(false); // Uncheck Disable radio button
  };

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Refill Balance</h3>
                <p className="text-white">Account Management</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body className="p-4 align-items-center">
                <Card className="current-balance bg-primary-subtle">
                  <div className="d-block flex-nowrap d-sm-flex align-items-center gap-2">
                    <span>Customer Balance:</span>
                    <h3>${customerBalance}</h3>
                  </div>
                </Card>
                <div className="container">
                  <Row className="align-items-center">
                    <Col xs={12} lg={6}>
                      <h5 className="mb-3">Refill Customer</h5>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Choose a Customer</Form.Label>
                          <Form.Select
                            value={refillid}
                            onChange={(e) => {
                              const selectedUserId = e.target.value;
                              setRefillid(selectedUserId);
                              getBalanceByUserId(selectedUserId);
                            }}
                          >
                            <option value="">Select Company</option>
                            {companyData.map((company) => (
                              <option
                                key={company.userid}
                                value={company.userid}
                              >
                                {company.company_name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Refill</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Refill Amount"
                            value={refill}
                            onChange={(e) => setRefill(e.target.value)}
                          />
                        </Form.Group>

                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                          <Button
                            variant="primary"
                            onClick={() => refillBalance(refillid)}
                          >
                            Refill Balance
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={resetForm}
                          >
                            Reset Form
                          </Button>
                        </div>
                      </Form>
                    </Col>
                    <Col xs={12} lg={6}>
                      <h5 className="mb-3">Manage Client Account</h5>
                      <Form>
                        <Form.Check
                          type="radio"
                          id="enableClient"
                          label="Enable Client"
                          checked={isEnableChecked}
                          onChange={handleEnableClick}
                        />
                        <Form.Check
                          type="radio"
                          id="disableClient"
                          label="Disable Client"
                          checked={isDisableChecked}
                          onChange={handleDisableClick}
                        />
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Confirmation Modals */}
      <Modal show={show0} onHide={handleClose0}>
        <Modal.Header closeButton>
          <Modal.Title>Disable Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to disable this client?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose0}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDisable}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Enable Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to enable this client?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmEnable}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Index;
