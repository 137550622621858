import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Select from "react-select";
import { Pagination } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = process.env.REACT_APP_API_URL;

const CallSummary = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [calls, setCalls] = useState([]); // Array to hold call data
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchClicked, setSearchClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;

  const Choice = [
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "This Week", label: "This Week" },
    { value: "Last Week", label: "Last Week" },
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
  ];

  const handleSearch = async () => {
    try {
      if (!selectedOption) {
        toast.error("Please select a call type");
        return;
      }

      let data = new URLSearchParams({
        userid: localStorage.getItem("userid"),
        call_type: selectedOption.value,
      });

      const response = await fetch(
        `${apiUrl}/callSummary.php?${data.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const responseData = await response.json();

        const callDataArray = Array.isArray(responseData)
          ? responseData
          : [responseData];

        setCalls(callDataArray);
        setTotalRecords(callDataArray.length);
        setSearchClicked(true);
      } else {
        toast.error("Failed to fetch call summary");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleReset = () => {
    setCalls([]);
    setTotalRecords(0);
    setSearchClicked(false);
    setCurrentPage(1);
    setSelectedOption(null);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedItems = calls.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12" lg="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Call Summary</h3>
                <p className="text-white">Summary</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Body className="p-4 align-items-center mb-3">
                <div className="container mb-5">
                  <div className="row">
                    <div className="col-sm">
                      <h5 className="mb-2 mt-3">Select type</h5>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectedOption}
                        onChange={setSelectedOption}
                        isClearable
                        options={Choice}
                      />
                    </div>
                    <div className="col-sm"></div>
                  </div>
                </div>
                <Button variant="primary" onClick={handleSearch}>
                  Search
                </Button>
                {"   "}
                <Button variant="danger" onClick={handleReset}>
                  Reset
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                {calls.length === 0 && searchClicked ? (
                  <div className="text-center text-muted">
                    No data available for the selected date range.
                  </div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table text-center">
                        <thead>
                          <tr>
                            <th scope="col">Call Type</th>
                            <th scope="col">Calls</th>
                            <th scope="col">Billed Mins</th>
                            <th scope="col">Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedItems.map((item, idx) => (
                            <tr key={idx}>
                              <td>{item.calltype}</td>
                              <td>{item.calls}</td>
                              <td>{item.billed_minutes}</td>
                              <td>{item.cost}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <span className="ml-3">
                        Total Records: {totalRecords} | Page {currentPage} of{" "}
                        {totalPages}
                      </span>

                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        total={totalPages}
                        limit={1}
                        activePage={currentPage}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default CallSummary;
