import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    // Function to handle localStorage updates
    const handleStorageChange = (e) => {
      if (e.key === key) {
        setStoredValue(e.newValue ? JSON.parse(e.newValue) : initialValue);
      }
    };

    // Listen to storage events
    window.addEventListener("storage", handleStorageChange);

    // Clean up event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  useEffect(() => {
    // Update localStorage whenever the state changes
    window.localStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}

const Header = () => {
  const [balance, setBalance] = useState("balance");

  useEffect(() => {
    const storedBalance = localStorage.getItem("balance");
    if (storedBalance) {
      const balanceValue = parseFloat(storedBalance);

      setBalance(
        balanceValue % 1 === 0
          ? balanceValue.toFixed(0)
          : balanceValue.toFixed(6)
      );
    }
  }, []);

  const minisidebar = () => {
    const sidebarMini = document.getElementsByTagName("ASIDE")[0];
    if (sidebarMini.classList.contains("sidebar-mini")) {
      sidebarMini.classList.remove("sidebar-mini");
    } else {
      sidebarMini.classList.add("sidebar-mini");
    }
  };

  return (
    <>
      <div className="iq-top-navbar">
        <Navbar
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center pb-2 pb-lg-0 d-xl-none">
              <Link
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
                to="#"
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  {" "}
                  menu{" "}
                </div>
              </Link>
            </div>
            <button className="available-balance">
              <div className="d-flex align-items-center gap-3">
                <div>
                  <h6 className="balance" id="accountBalance">
                    $ {balance}
                  </h6>
                  <p className="mb-0 font-size-11 fw-medium">
                    Available Balance
                    <span className="material-symbols-outlined info-icon">
                      info
                    </span>
                  </p>
                </div>
              </div>
            </button>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
