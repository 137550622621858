import React, { useEffect } from "react";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";

//images
import Logo from "../../../../assets/images/logo.png";
import name from "../../../../assets/images/logo-name.png";

//react-bootstrap
import { Accordion, Nav, Tooltip, OverlayTrigger, Card } from "react-bootstrap";

//components
import Verticalnav from "./verticalnav";
import Scrollbar from "smooth-scrollbar";

import { toast } from "react-toastify";

import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  const username = localStorage.getItem("username");

  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);
  const appName = useSelector(SettingSelector.app_name);
  useEffect(() => {
    Scrollbar.init(document.querySelector(".data-scrollbar"));

    window.addEventListener("resize", () => {
      const tabs = document.querySelectorAll(".nav");
      const sidebarResponsive = document.querySelector(
        '[data-sidebar="responsive"]'
      );

      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains("sidebar-mini")) {
            sidebarResponsive.classList.add("sidebar-mini", "on-resize");
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (
            sidebarResponsive.classList.contains("sidebar-mini") &&
            sidebarResponsive.classList.contains("on-resize")
          ) {
            sidebarResponsive.classList.remove("sidebar-mini", "on-resize");
          }
        }
      }
    });
  });

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  let location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("username")) {
      // navigate("/auth/sign-in");
      toast.success("Sign out success");
    }
  });

  return (
    <>
      <aside
        className={`${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} sidebar sidebar-default sidebar-base navs-rounded-all`}
        id="first-tour"
        data-toggle="main-sidebar"
        data-sidebar="responsive"
      >
        <div className="sidebar-header d-flex align-items-center justify-content-start position-relative">
          <Link
            to="/"
            className="d-flex align-items-center gap-2 iq-header-logo"
          >
            <img src={Logo} className="Logo"></img>
            <img src={name} className="logo-title"></img>
          </Link>
          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={minisidebar}
          >
            <span className="menu-btn d-inline-block is-active">
              <i className="right-icon material-symbols-outlined icon-rtl">
                chevron_left
              </i>
            </span>
          </div>
        </div>
        <div className="sidebar-body pt-0 data-scrollbar">
          <div className="sidebar-list">
            <Verticalnav />
          </div>
        </div>
        <div className="sidebar-footer">
          <Card className="sideBarCard">
            <span className="material-symbols-outlined icon-help">help</span>

            <div className="cardContent">
              <span>Email</span>
              <a href="mailto:sales@vaulttel.ph">
                <h5>sales@vaulttel.ph</h5>
              </a>
              <span>Call</span>
              <a href="tel:0945-746-7489">
                <h5>0945-746-7489</h5>
              </a>
            </div>
          </Card>
          <Accordion
            as="ul"
            className="navbar-nav iq-main-menu"
            id="sidebar-menu"
          >
            <Nav.Item as="li">
              <Link className="nav-link" aria-current="page" to="/auth/sign-in">
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Sign-Out</Tooltip>}
                >
                  <i className="icon material-symbols-outlined">logout</i>
                </OverlayTrigger>
                <span
                  className="item-name "
                  onClick={() => {
                    localStorage.clear();
                  }}
                >
                  Sign-Out
                </span>
              </Link>
            </Nav.Item>
          </Accordion>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
