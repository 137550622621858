import React from "react";

// auth
import SignIn from "../views/dashboard/auth/sign-in";


export const SimpleRouter = [
  {
    path: "auth/sign-in",
    element: <SignIn />,
  },
  
];
